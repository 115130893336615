<template>
    <section>
        <modal ref="editarProd" :titulo="nuevo.nombre" no-aceptar :img="nuevo.imagen" :adicional="btn_adicional" @adicional="accion_adiccional">
            <div class="row mx-0 my-2 f-600 f-14 justify-center">
                {{ convertMoneyTendero(nuevo.precio,pedido.idm_moneda) }}
            </div>
            <div class="row mx-0 my-2 mt-3 f-15 justify-center">
                <div class="col-10 text-center">
                    {{nuevo.descripcion}}
                </div>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2">
                    Presentación:
                </b>
                <p>{{nuevo.presentacion}}</p>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2">
                    Unidad de medida:
                </b>
                <p>{{nuevo.unidad}}</p>
            </div>
            <div class="row mx-0 my-2 f-15 justify-center">
                <b class="mr-2">
                    Venta mínima:
                </b>
                <p>{{nuevo.cantidad_minima}} {{nuevo.unidad}}.</p>
            </div>
            <div class="row mx-0 my-2 f-15 text-primary justify-center">
                <b class="mr-2">
                    Cantidad Pedida:
                </b>
                <p>{{nuevo.cantidad_pedida}}</p>
            </div>
            <hr v-show="estado=='editado'" />
            <div v-show="estado=='editado'" class="row mx-0 my-2 f-15 text-gr-red justify-center">
                <b class="mr-2">
                    Cantidad Inicial:
                </b>
                <p>{{ antiguo.cantidad_inicial }}</p>
            </div>
            <hr v-show="estado=='editado'" />
            <div v-show="tipo === '' || tipo === 'cantidad'" class="mt-4">
                <div class="row justify-center">
                    <div class="col-4 px-0 text-center br-10  mx-2 position-relative">
                        <label class="position-absolute f-12" style="top:-17px; left:41px">Editar Cantidad</label>
                        <el-input-number
                        v-model="model.cantidad"
                        :min="minimo" :step="salto"
                        :step-strictly="entero"
                        :precision="presicion"
                        style="width:160px;"
                        />
                    </div>
                    <div class="col-3 text-center br-10 bg-general text-white cr-pointer p-2 mx-2" @click="tipo='remplazar',btn_adicional='Aceptar'">
                        Reemplazar
                    </div>
                    <div class="col-3 text-center br-10 bg-general2 text-white cr-pointer p-2 mx-2" @click="accion_revertir">
                        Revertir
                    </div>
                </div>
            </div>
            <div class="row mx-auto px-3 mt-4">
                <div class="w-100">
                    <label class="f-13">Comentario</label>
                    <el-input v-model="comentario" type="textarea" :rows="2" show-word-limit maxlength="200" />
                </div>
            </div>
            <hr />

            <div v-show="estado=='desconocido' && tipo === ''" class="">
                <div class="row mx-0 my-2 f-14 justify-center f-600">
                    Producto Inicial
                </div>
                <div class="row mx-0 my-2 justify-center">
                    <img class="border bg-whitesmoke br-5" src="/img/no-imagen/question-mark.svg" width="90" height="90" alt="" />
                </div>
                <div class="row mx-0 my-2 f-600 f-14 justify-center">
                    Producto Desconocido
                </div>

                <div class="row mx-0 my-2 mt-3 f-15 justify-center">
                    <div class="col-10 text-center">
                        {{ desconocido.descripcion }}
                    </div>
                </div>
            </div>


            <div v-show="estado=='cambio' && tipo === ''" class="">
                <div class="row mx-0 my-2 f-14 justify-center f-600">
                    Producto Inicial
                </div>
                <div class="row mx-0 my-2 justify-center">
                    <img class="border bg-whitesmoke br-5 p-2" :src="antiguo.imagen" width="90" height="90" alt="" />
                </div>
                <div class="row mx-0 my-2 f-600 f-14 justify-center">
                    {{ antiguo.nombre }}
                </div>
                <div class="row mx-0 my-2 f-600 f-14 justify-center">
                    {{ convertMoneyTendero(antiguo.precio,pedido.idm_moneda) }}
                </div>
                <div class="row mx-0 my-2 mt-3 f-15 justify-center">
                    <div class="col-10 text-center">
                        {{ antiguo.descripcion }}
                    </div>
                </div>
                <div class="row mx-0 my-2 f-15 justify-center">
                    <b class="mr-2">
                        Presentación:
                    </b>
                    <p>{{antiguo.presentacion}}</p>
                </div>
                <div class="row mx-0 my-2 f-15 justify-center">
                    <b class="mr-2">
                        Unidad de medida:
                    </b>
                    <p>{{ antiguo.unidad }}</p>
                </div>
                <div class="row mx-0 my-2 f-15 justify-center">
                    <b class="mr-2">
                        Venta mínima:
                    </b>
                    <p>{{ antiguo.cantidad_minima }} {{ antiguo.unidad }}.</p>
                </div>
                <div class="row mx-0 my-2 f-15 text-gr-red justify-center">
                    <b class="mr-2">
                        Cantidad pedida:
                    </b>
                    <p>{{ antiguo.cantidad_pedida }}</p>
                </div>
            </div>

            <div v-show="tipo=='remplazar'" class="row mx-0 my-2 justify-center">
                <div class="col-8 text-center">
                    <p>Buscar producto de reemplazo</p>
                    <el-select
                    v-model="model.id_producto_nuevo"
                    size="small"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Buscar producto"
                    :remote-method="remoteMethod"
                    :loading="loading"
                    @change="set_minimo"
                    >
                        <el-option
                        v-for="item in opciones"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div v-show="model.id_producto_nuevo !== null" class="col-8 text-center mt-4">
                    <div class="">
                        <p>Cantidad</p>
                        <el-input-number v-model="model.cantidad_nueva" size="small" :min="minimo_nuevo" :step="salto_nuevo" step-strictly :precision="2" />
                    </div>
                </div>
            </div>

        </modal>
        <modalConfirmar
        ref="modalRevertir"
        titulo="Revertir cambios"
        mensaje="¿Desea revertir los cambios al producto?" icon="shopping-cart"
        @guardar="revertir_cambios"
        />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            estado: '',
            nuevo:{nombre:''},
            antiguo:{},
            desconocido:{},
            datos:{},
            hidden:true,
            btn_adicional:'Aceptar',
            model:{
                id_producto_nuevo:null,
                cantidad:'',
                cantidad_nueva:0
            },
            loading:false,
            tipo:'',
            minimo_nuevo:0,
            salto_nuevo:0,
            entero_nuevo:false,
            presicion_nuevo:0,
            minimo:0,
            salto:0,
            entero:false,
            presicion:0,
            opciones:[],
            comentario:''
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
        }),
    },
    methods: {
        toggle(tipo){
            this.$refs.editarProd.toggle()
            this.estado = tipo
        },
        limpiar(){
            this.estado = ''
            this.tipo = '',
            this.btn_adicional = 'Aceptar',
            this.opciones = []
        },
        async datos_cambio(info){
            try {

                this.limpiar()

                const {data} = await Pedidos.producto_cambiado(info.id)
                this.datos = info

                this.minimo = parseFloat(info.producto.cantidad_minima)
                this.salto = parseFloat(info.producto.cantidad_minima)
                this.entero = info.producto.cantidad_tipo === 1
                this.presicion = info.producto.cantidad_tipo === 1 ? 0 : 2
                this.model.cantidad = parseFloat(info.cantidad)
                this.comentario = info.estado_comentario

                this.nuevo = {
                    id:info.producto.id,
                    nombre:info.producto.nombre,
                    descripcion:info.producto.descripcion,
                    imagen:info.producto.imagen,
                    precio: info.unidad_final,
                    presentacion:info.producto.presentacion,
                    unidad:info.producto.unidad,
                    cantidad_minima:this.agregarSeparadoresNumero(info.producto.cantidad_minima,2),
                    cantidad_pedida: this.agregarSeparadoresNumero(info.cantidad,2) + info.producto.unidad + ': ' + this.convertMoneyTendero(info.total_final,this.pedido.idm_moneda)

                }

                if(data.inicial.cambio){
                    if(data.inicial.desconocido){
                        this.estado = 'desconocido'
                        this.desconocido = {
                            descripcion:data.inicial.nombre
                        }

                    }else{
                        this.estado = 'cambio'
                        this.antiguo = {
                            id:data.inicial.id,
                            nombre:data.inicial.nombre,
                            descripcion:data.inicial.descripcion,
                            imagen:data.inicial.imagen,
                            precio: data.inicial.unidad_final,
                            presentacion:data.inicial.presentacion,
                            unidad:data.inicial.unidad,
                            cantidad_minima:this.agregarSeparadoresNumero(data.inicial.cantidad_minima,2),
                            cantidad_pedida: this.agregarSeparadoresNumero(data.inicial.cantidad,2) + data.inicial.unidad + ': ' + this.convertMoneyTendero(data.inicial.total_final,this.pedido.idm_moneda)

                        }
                    }
                }else{
                    this.estado = 'editado' //
                    this.antiguo = {
                        cantidad_inicial: this.agregarSeparadoresNumero(data.inicial.cantidad,2) + info.producto.unidad + ': ' + this.convertMoneyTendero(data.inicial.total_final,this.pedido.idm_moneda)
                    }
                }

                this.$refs.editarProd.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        async remoteMethod(query){
            try {
                if(query.length > 3){

                    this.loading = true

                    let params = {
                        id_pedido:this.id_pedido,
                        query
                    }

                    const {data} = await Pedidos.buscar_productos(params)
                    this.opciones = data.productos
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        set_minimo(id){
            let obj = this.opciones.find(o=>o.id === id)
            this.model.cantidad_nueva = parseFloat(obj.cantidad_minima)
            this.minimo_nuevo = parseFloat(obj.cantidad_minima)
            this.salto_nuevo = parseFloat(obj.cantidad_minima)
            this.entero_nuevo =obj.cantidad_tipo === 1
            this.presicion_nuevo = obj.cantidad_tipo === 1 ? 0 : 2
        },
        async accion_adiccional(){

            try {

                if(this.tipo === 'cantidad' || this.tipo === ''){
                    let model = {
                        id_producto:this.nuevo.id,
                        cantidad:this.model.cantidad,
                        comentario:this.comentario
                    }
                    const {data} = await Pedidos.alter_producto(this.datos.id, model)
                    this.notificacion('Mensaje','Cantidad Actualizada','success')
                    this.$refs.editarProd.toggle()

                    //recalculamos todos los datos
                    this.recalcular_pedido()

                }


                if(this.tipo === 'remplazar'){
                    if(this.model.id_producto_nuevo === null){
                        this.notificacion('Advertencia','Por favor seleccione el producto nuevo','warning')
                        return
                    }
                    let model = {
                        id_producto:this.model.id_producto_nuevo,
                        cantidad:this.model.cantidad_nueva,
                        comentario:this.comentario
                    }
                    const {data} = await Pedidos.alter_producto(this.datos.id, model)
                    this.notificacion('Mensaje','Producto remplazado','success')
                    this.$refs.editarProd.toggle()

                    //recalculamos todos los datos
                    this.recalcular_pedido()
                }




            } catch (e){
                this.error_catch(e)
            }
        },
        accion_revertir(){
            this.$refs.editarProd.toggle()
            this.$refs.modalRevertir.toggle()
        },
        async revertir_cambios(){
            try {

                const {data} = await Pedidos.revertir_cambio(this.datos.id)
                this.notificacion('Mensaje','Cambios en el producto revertidos correctamente','success')
                this.$refs.modalRevertir.toggle()
                this.recalcular_pedido()

            } catch (e){
                this.error_catch(e)
            }
        },
        recalcular_pedido(){
            // this.$store.dispatch('pedidos/pedidos/listar_pedidos')
            this.$store.dispatch('pedidos/pedidos/pedidos_productos', Number(this.id_pedido))
            this.$store.dispatch('pedidos/pedidos/pedidos_resumenes', Number(this.id_pedido))
        }
    }
}
</script>
